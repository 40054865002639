import React, { useEffect, useState } from 'react';
import "./Home.css"
import buste from "../../assets/buste.png"
import cross from "../../assets/cross.png"
import emailjs from '@emailjs/browser';
import CustomizedSnackbars from '../Snackbar';

export const Home = () => {
    const [isForm, setForm] = useState(false)


    return <div className='home-background'>
        {isForm ? <ContactForm setForm={setForm}/> : <></>}
        <div className='home-left'>
            <h1 className='home-title'>
                WELCOME TO
            </h1>
            <h1 className='home-title'>
                THE FUTURE
            </h1>
            <h1 className='home-title'>
                OF ART
            </h1>
            <span className='home-text'>
            We empower galleries and artists with secure, digital certificates of authenticity. Using blockchain technology, we provide verifiable provenance and tamper-proof records for artworks, revolutionizing the art world with trusted and transparent certification solutions.
            </span>
            <button className='home-button' onClick={() => {
                setForm(true)
            }}>
                Contact us
            </button>
        </div>
        <img src={buste} style={{width: '30vw'}}/>
    </div>
}

interface ContactFormProps {
    setForm: any
}

export const ContactForm = ({setForm}: ContactFormProps) => {
    const [fullName, setFullName] = useState("")
    const [email, setEmail] = useState("")
    const [country, setCountry] = useState("")
    const [message, setMessage] = useState("")
    const [open, setOpen] = useState(false)
    const [openError, setOpenError] = useState(false)
    
    useEffect(() => emailjs.init("user_4totNko6Xrs20EjGdyXMr"), []);
    
    const sendEmail = async () => {
        try {
            setOpen(true)
            await emailjs
            .send("service_0q8o6ze", "template_7q4neg6", {from_name: fullName, email: email, country: country, message: message})
            .then(
              () => {
                console.log('SUCCESS!');
                setForm(false)
              },
              (error) => {
                console.log('FAILED...', error.text);
              },
            );            
        }
        catch (err) {
            console.log(err)
        }

      }
    return <div className='home-contact-form'>
        <div className='home-contact-form-window'>
            <div style={{display: 'flex', justifyContent: "center", alignItems: 'start', flexDirection: 'column', width: '90%', marginTop: '2vh'}}>
            <button style={{alignSelf: 'start', background: "none", border: "none", cursor: 'pointer'}} onClick={() => {
                setForm(false)
            }}>
                <img src={cross} style={{maxWidth: '1vw'}}/>
                </button>
            </div>
            <div style={{display: 'flex', justifyContent: "center", alignItems: 'start', flexDirection: 'column', width: '80%', marginTop: '1vh'}}>
                <h2 className='home-form-title'>
                Interested in our project ?
                </h2>
                <span className='home-form-text'>
                Ask us any question, we will get back to you as soon as possible.
                </span>
            </div>

            <input name="fullname" placeholder='Full Name' className='home-textfield' value={fullName} onChange={(e) => setFullName(e.target.value)}/>
            <input name="email" placeholder='Email' className='home-textfield' value={email} onChange={(e) => setEmail(e.target.value)}/>
            <input name="country" placeholder='Country of residence' className='home-textfield' value={country} onChange={(e) => setCountry(e.target.value)}/>
            <textarea name="description" placeholder='Explain us your project' className='home-textfield-description' value={message} onChange={(e) => setMessage(e.target.value)}/>
            <button className='home-form-button' onClick={() => {
                if (fullName == "" || email == "" || country == "" || message == "") {
                    setOpenError(true)
                }
                else {
                    sendEmail()
                }
            }}>
                Submit
            </button>
        </div>
        <CustomizedSnackbars open={openError} setOpen={setOpenError} type={"error"} message={"Please fill the form before submit"}/>
        <CustomizedSnackbars open={open} setOpen={setOpen} type={"success"} message={"Thanks ! We will get back to you as soon as possible"}/>

    </div>
}

export default Home